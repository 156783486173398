import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './root/app.component';
import { StartComponent } from './pages/start/start.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { DatenschutzComponent } from './pages/datenschutz/datenschutz.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ImageCarouselComponent } from './pages/shared/image-carousel/image-carousel.component';
import { InfoboxComponent } from './pages/shared/infobox/infobox.component';
import {CarouselModule, CollapseModule} from 'ngx-bootstrap';
import { ZimmerComponent } from './pages/zimmer/zimmer.component';
import { ZimmerItemComponent } from './pages/zimmer/zimmer-item/zimmer-item.component';
import { AusstattungComponent } from './pages/ausstattung/ausstattung.component';
import { AusstattungItemComponent } from './pages/ausstattung/ausstattung-item/ausstattung-item.component';
import { AnreiseComponent } from './pages/anreise/anreise.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CookieLawModule} from 'angular2-cookie-law';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    ImpressumComponent,
    DatenschutzComponent,
    NavigationComponent,
    ImageCarouselComponent,
    InfoboxComponent,
    ZimmerComponent,
    ZimmerItemComponent,
    AusstattungComponent,
    AusstattungItemComponent,
    AnreiseComponent,
    KontaktComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BrowserAnimationsModule,
    CookieLawModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
