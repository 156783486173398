import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent {

  readonly images = ['haus1.jpg', 'haus2.jpg', 'haus4.jpg', 'haus3.jpg'];

}
