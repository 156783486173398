import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-ausstattung-item',
  templateUrl: './ausstattung-item.component.html',
  styleUrls: ['./ausstattung-item.component.scss']
})
export class AusstattungItemComponent {

  @Input()
  title: string;

  @Input()
  details: string[];

}
