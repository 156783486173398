import {Routes} from '@angular/router';
import {ImpressumComponent} from '../pages/impressum/impressum.component';
import {DatenschutzComponent} from '../pages/datenschutz/datenschutz.component';
import {StartComponent} from '../pages/start/start.component';
import {ZimmerComponent} from '../pages/zimmer/zimmer.component';
import {AusstattungComponent} from '../pages/ausstattung/ausstattung.component';
import {AnreiseComponent} from '../pages/anreise/anreise.component';
import {KontaktComponent} from '../pages/kontakt/kontakt.component';

export const routes: Routes = [
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent
  },
  {
    path: 'zimmer',
    component: ZimmerComponent
  },
  {
    path: 'ausstattung',
    component: AusstattungComponent
  },
  {
    path: 'anreise',
    component: AnreiseComponent
  },
  {
    path: 'kontakt',
    component: KontaktComponent
  },
  {
    path: '',
    component: StartComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/'
  }
];
