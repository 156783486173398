import {Component, Input} from '@angular/core';
import {Zimmer} from '../zimmer';

@Component({
  selector: 'app-zimmer-item',
  templateUrl: './zimmer-item.component.html',
  styleUrls: ['./zimmer-item.component.scss']
})
export class ZimmerItemComponent {

  @Input()
  zimmer: Zimmer;

}
