import { Component, OnInit } from '@angular/core';
import {Zimmer} from './zimmer';

@Component({
  selector: 'app-zimmer',
  templateUrl: './zimmer.component.html',
  styleUrls: ['./zimmer.component.scss']
})
export class ZimmerComponent {

  readonly zimmers: Zimmer[] = [
    {
      title: 'Doppelzimmer',
      preis: '35,00',
      details: [
        'Bei längerem Aufenthalt Preise auf Anfrage',
        'Küchenzeile mit Kaffeemaschine vorhanden'
      ],
      ausstattung: [
        'Balkon',
        'Fön',
        'Nichtraucherzimmer',
        'WLAN',
        'Dusche',
        'WC'
      ],
      bilder: [
        'dz/2.jpg'
      ]
    },
    {
      title: 'Einzelzimmer',
      preis: '50,00',
      details: [],
      ausstattung: [
        'Nichtraucherzimmer',
        'WLAN'
      ],
      bilder: []
    },
    {
      title: 'Mehrbettzimmer',
      preis: '30,00',
      details: [
        'Zustellbett 15 €'
      ],
      ausstattung: [
        'Zustellbett möglich',
        'Fön',
        'Terrasse',
        'Nichtraucherzimmer',
        'Dusche',
        'WC',
        'WLAN'
      ],
      bilder: [
        'mz/1.jpg'
      ]
    }
  ];

}
