import {Component} from '@angular/core';

@Component({
  selector: 'app-ausstattung',
  templateUrl: './ausstattung.component.html',
  styleUrls: ['./ausstattung.component.scss']
})
export class AusstattungComponent {

  readonly ausstattung = [
    'Rauchfreier Gastgeber',
    'WLAN kostenfrei',
    'Parkplatz',
    'Hausterrasse'
  ];

  readonly lage = [
    'Ortsrand',
    'Ruhige Lage',
    'Zentrumsnah',
    'Entfernung Bahnhof: ca. 1 km',
    'Entfernung Flughafen: ca. 40 km',
    'Entfernung Autobahnausfahrt: ca. 1,5 km',
    'Entfernung zu öffentlichen Verkehrsmitteln: ca. 500 m'
  ];

  readonly tiere = [
    'Keine Haustiere'
  ];

  readonly freizeit = [
    'Abschließbarer Fahrradstellplatz',
    'fahrradfreundlich'
  ];

  readonly zahlung = [
    'Barzahlung'
  ];

  readonly familien = [
    'Kinder willkommen',
    'Kinderhochstuhl',
    'Spielplatz in der Nähe'
  ];


  readonly sport = [
    '<a href="https://www.badenweiler.de/Cassiopeia-Therme">Cassiopeia Therme, Badenweiler</a>',
    '<a href="https://www.balinea.de">Balinea Thermen, Bad Bellingen</a>',
    'Angeln',
    'Baden',
    'Fahrrad fahren',
    'Golf spielen',
    'Inline skaten',
    'Kegeln',
    'Mountainbiken',
    'Nordic Walking',
    'Reiten',
    'Tennis',
    'Wandern'
  ];

}
