import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  @ViewChild('navBar') navBar;

  readonly navItems: NavItem[] = [
    {
      label: 'Startseite',
      url: '/'
    },
    {
      label: 'Zimmer & Preise',
      url: '/zimmer'
    },
    {
      label: 'Ausstattung',
      url: '/ausstattung'
    },
    {
      label: 'Anreise',
      url: '/anreise'
    },
    {
      label: 'Kontakt',
      url: '/kontakt'
    },
    {
      label: 'Datenschutz',
      url: '/datenschutz',
      legal: true
    },
    {
      label: 'Impressum',
      url: '/impressum',
      legal: true
    }
  ];

  constructor(private router: Router) {
  }

  isActive(navItem: NavItem): boolean {
    return this.router.isActive(navItem.url, true);
  }

  navigate(navItem: NavItem): void {
    this.router.navigateByUrl(navItem.url).then(() => this.closeNavBar());
  }

  private closeNavBar() {
    // Hack to close navbar after selecting a link
    $('.navbar-collapse').collapse('hide');
  }
}

class NavItem {
  label: string;
  url: string;
  legal?: boolean;
}
